import * as React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Carousel } from "react-bootstrap";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

const Concrete = () => (
  <Layout>
    <SEO
      title="Asphalt"
      image={"/water-reticulation.jpg"}
      description="From trenching and construction through to commissioning and
      maintenance, our team has a strong track record of delivering
      infrastructure for small to large scale water projects."
    />

    <div className="row g-0">
      <div className="col-lg-6 d-flex align-items-center justify-content-center">
        <div className="content-box">
          <h1 className="section-heading section-heading--left">
            Water Reticulation
          </h1>
          <p>
            From trenching and construction through to commissioning and
            maintenance, our team has a strong track record of delivering
            infrastructure for small to large scale water projects.
          </p>

          <ul>
            <li>Pipework</li>
            <li>Mechanical</li>
            <li>Pumping stations</li>
            <li>Storage tanks</li>
            <li>Water Connections</li>
            <li>Polyethylene (PE) pipe welding</li>
          </ul>
          <p>
            Our team host strong capabilities working with a variety of water
            pipe materials, manufactured from PE, PVC, CLS, and DI.
          </p>
          <p>
            Sierra Delta maintain an Approved Contractors endorsement to
            undertake new water supply connections for several agencies in the
            Wellington and Wairarapa regions, meaning we can manage your
            installations from start to sign off.
          </p>
          <p>
            <Link className="btn btn-primary mt-4" to="/contact/">
              Free quote
            </Link>
          </p>
        </div>
      </div>
      <div className="col-lg-6 d-flex align-items-center justify-content-center ">
        {/* <Carousel>
              <Carousel.Item>
                <StaticImage
                  aspectRatio={16 / 10}
                  src="../images/concrete.jpg"
                  alt="concrete path"
                  loading="eager"
                />
              </Carousel.Item>
            </Carousel> */}
        <StaticImage
          aspectRatio={16 / 14}
          transformOptions={{ cropFocus: "south" }}
          src="../images/water-reticulation.jpg"
          alt="Water Reticulation"
          loading="eager"
        />
      </div>
    </div>
  </Layout>
);

export default Concrete;
